<template>
  <div class="tabs " v-if="list_tab">
    <div
      class="menu-link"
      v-for="tab in list_tab"
      :key="tab.name"
      :class="{ focus: tabsActive === tab.name }"
      @click="handleChangeTbas(tab)"
    >
      {{ tab.name }}
      <div class="title" v-if="tab.name === 'Fichiers'">{{ occ }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active_tabs: {
      type: String,
      default: null
    },
    list_tab: {
      type: Array
    },
    occ: { type: Number }
  },

  data() {
    return {
      tabsActive: this.active_tabs !== null ? this.active_tabs : null
    };
  },
  methods: {
    handleChangeTbas(tab) {
      this.tabsActive = tab.name;
      this.$emit('tabsChange', this.tabsActive);
    }
  }
};
</script>

<style scoped lang="scss">
.tabs {
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
  .menu-link {
    display: flex;
    margin: 0px 3px;
    font-size: 12px;
    background-color: #ffffff;
    box-shadow: 0px 4px 6px #0000003d;
    border-radius: 10px 10px 0px 0px;
    padding: 7px 20px;
    .title {
      line-height: 1.5px;
      border-radius: 15px 15px 0px 0px;
      display: block;
      font-size: 13px;
      font-weight: 600;
      color: #5d5d5d;
      margin-bottom: 4px;
      padding: 8px 10px;
      text-decoration: none;
      font-family: 'Montserrat', sans-serif;
    }

    &:hover {
      color: #fff;
      background-color: #9799d6;
    }
  }
  .focus {
    color: #fff;
    background-color: #4d4bac;
    .title {
      color: #fff;
    }
  }
}
</style>
